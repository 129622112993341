<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <page-head title="我加入的企业"> </page-head>
    <div class="trial" @click="demoClick">试用体验版本</div>
    <a-layout>
      <!-- <a-layout-header> -->
      <a-menu
        mode="horizontal"
        v-model:selectedKeys="selectedKeys"
        class="menu"
      >
        <a-menu-item v-for="item in menu" :key="item.id">
          <router-link replace :to="item.path">{{ item.label }}</router-link>
        </a-menu-item>
      </a-menu>
      <!-- </a-layout-header> -->
      <a-layout-content class="view">
        <div
          :style="{ background: '#fff', padding: '24px', minHeight: '280px' }"
        >
          <!-- 页面 -->
          <router-view />
        </div>
      </a-layout-content>
    </a-layout>
    <jion-demo ref="message" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import jionDemo from '@/components/jionDemo'

export default defineComponent({
  components: {
    'page-head': PageHead,
    'jion-demo': jionDemo,
  },
  data () {
    return {
      menu: [
        {
          id: 1,
          path: '/UserManagement/MyEnterprise/AlreadyJionEnterprise',
          label: '已加入企业',
        }, {
          id: 2,
          path: '/UserManagement/MyEnterprise/JionEnterprise',
          label: '加入企业',
        }, {
          id: 3,
          path: '/UserManagement/MyEnterprise/CreatEnterprise',
          label: '创建企业',
        },
      ],
      selectedKeys: [],
    }
  },
  created () {
    this.minHeight = minheight
    const path = this.$route.path
    this.selectKey(path)
  },
  watch: {
    $route (to) {
      this.selectKey(to.path)
    },
  },
  methods: {
    demoClick () {
      this.$refs.message.openInfo()
    },
    selectKey (path) {
      const item = this.menu.find(function (item) {
        return item.path === path
      })
      if (item) {
        const selectedKeys = []
        selectedKeys.push(item.id)
        this.selectedKeys = selectedKeys
      }
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  position: relative;
}
.trial {
  color: #134ccf;
  position: absolute;
  right: 20px;
  top: 18px;
  font-size: 18px;
  cursor: pointer;
}
.box {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 330px;
  padding: 0 20px;
}
.list {
  width: 100%;
  padding-left: 5%;
  height: 46px;
  border: 1px solid #dddddd;
  line-height: 46px;
  margin-bottom: 10px;
}
.lists {
  border: 1px solid #134ccf;
}
.image {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.box::-webkit-scrollbar {
  width: 10px;
  /**/
}
.box::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.box::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.box::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}
</style>
