<template>
  <div id="jionDemo">
    <a-modal
      v-model:visible="visible"
      title="试用版本"
      ok-text="确定"
      cancel-text="取消"
      :maskClosable="false"
      @cancel="handelCancel"
      @ok="handelOk"
      :width="400"
    >
      <a-spin :spinning="spinning">
        <div class="box">
          <div @click="demo(item)" v-for="item in demoData" :key="item.id">
            <div
              :class="
                selectData.id === item.id || selectData.industryId === item.id
                  ? 'list lists'
                  : 'list '
              "
            >
              <img class="image" src="@/assets/small.png" alt="" />
              {{ item.industryName }}
            </div>
          </div>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { joinDemo, getDemoList, switchTenant, getProfileDetail } from '@/api/IdentityAPI.js'
import api from '@/api/API'

export default {
  props: {},
  data () {
    return {
      spinning: false,
      visible: false,
      demoData: [],
      selectData: '',
      tenantList: {},
    }
  },
  created () {
    this.getJoinDemoTenant()
  },
  methods: {
    // 点击事件，呼出界面
    openInfo () {
      this.visible = true
    },
    getJoinDemoTenant () {
      this.spinning = true
      getDemoList().then(data => {
        this.spinning = false
        this.demoData = data
      }).catch(err => {
        this.spinning = false
        console.log(err)
      })
    },
    demo (e) {
      this.selectData = e
    },
    async handelOk () {
      this.$message.loading('正在切换企业')
      joinDemo({ id: this.selectData.trialTemplateId }).then(data => {
        switchTenant(data).then(data => {
          localStorage.setItem('isBan', JSON.stringify(data.isBan))
          this.$store.dispatch('account/setToken', data.rawData)
          this.$store.dispatch('account/setTenant', {
            tenantId: data.tenantId,
          })
          var url = window.location.href
          window.location.href = url
          // var parm = parseInt(Math.random() * 10)
          // if (url.lastIndexOf('?') > -1) {
          //   url = url + parm
          // } else {
          //   url = url + '?' + parm
          // }
          // window.onresiz = function () {
          //   console.log(123)
          //   window.location.href = url
          // }
          window.location.href = '/WorkManagement/WorkBench'
          getProfileDetail().then(data => {
            if (data.status === 1) {
              this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
              window.location.href = '/account/login'
              window.localStorage.clear()
            }
          }).catch(err => { console.log(err) })
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        this.$message.error('切换失败，请重试')
        console.log(err)
      })
    },
    handelCancel () {
      this.visible = false
      this.selectData = ''
    },
    selectKey (path) {
      const item = this.menu.find(function (item) {
        return item.path === path
      })
      if (item) {
        const selectedKeys = []
        selectedKeys.push(item.id)
        this.selectedKeys = selectedKeys
      }
    },
  },
}
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.box {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 330px;
  padding: 0 20px;
}
.list {
  width: 100%;
  padding-left: 5%;
  height: 46px;
  border: 1px solid #dddddd;
  line-height: 46px;
  margin-bottom: 10px;
}
.lists {
  border: 1px solid #134ccf;
}
.image {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.box::-webkit-scrollbar {
  width: 10px;
  /**/
}
.box::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.box::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.box::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}
</style>
